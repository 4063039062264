import React, {Component} from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Timeline.css'
export default class Timeline extends Component {

    state = {
        selected: [],
    };
     
    onSelect(items){
       
    }

    render() {
        return (
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work current"
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date="March 2018 - present"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<i className="fas fa-briefcase fa-2x" ></i>}
                >
                    <h3 className="vertical-timeline-element-title">Principal Applications Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Redwood City, CA</h4>
                    <p className="text-white">
                    Working as part of the Fusion HCM Cloud Learning team building UIs
and reusable components using ADF, JS and CSS.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--award"
                    date="2015"
                    iconStyle={{ background: 'rgb(206, 171, 0)', color: '#fff' }}
                    icon={<i className="fas fa-award fa-2x"></i>}
                >
                    <h5 className="vertical-timeline-element-title">Design Jam Winner</h5>
                    <h6 className="vertical-timeline-element-subtitle">Awarded by Oracle User Experience</h6>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--award"
                    date="2015"
                    iconStyle={{ background: 'rgb(206, 171, 0)', color: '#fff' }}
                    icon={<i className="fas fa-award fa-2x"></i>}
                >
                    <h5 className="vertical-timeline-element-title">Design Jam People's Choice</h5>
                    <h6 className="vertical-timeline-element-subtitle">Awarded by Oracle User Experience</h6>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="April 2012"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<i className="fas fa-briefcase fa-2x" ></i>}
                >
                    <h3 className="vertical-timeline-element-title">Principal Applications Engineer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Reading, England</h4>
                    <p>
                    Working​ ​alongside multiple ​Fusion​ ​HCM​ ​teams, designing and​ ​building​ ​UIs​ ​and reusable​ ​components using ADF, JS and CSS. Other main tasks involved:
providing technical Support for HCM developers working on Fusion UIs.
investigating newly-released ADF components, learning their capabilites and sharing my findings with development teams.
</p>
                </VerticalTimelineElement>

<VerticalTimelineElement
                    className="vertical-timeline-element--award"
                    date="2008"
                    iconStyle={{ background: 'rgb(206, 171, 0)', color: '#fff' }}
                    icon={<i className="fas fa-award fa-2x"></i>}
                >
                    <h5 className="vertical-timeline-element-title">Personal Achievement Award</h5>
                    <h6 className="vertical-timeline-element-subtitle">Awarded by Steve Miranda (Sr. Vice President)</h6>
                    <p>
                    For my work on the Fusion Prototype Pilot in which I created the "Kudos" (giving feedback) component, as well as integrating Email and Chat functionality into the Worker Directory using Oracle WebCenter.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="August 2007 - August 2008"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<i className="fas fa-briefcase fa-2x"></i>}
                >
                    <h3 className="vertical-timeline-element-title">Applications Developer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Reading, England</h4>
                    <p>
                    Intern year as part of the university degree.  Working within a small team of IT architects (Core HR Architecture), whose main role in the organisation was to help coordinate architectural activities across HR for their next generation enterprise application suite, Fusion. 
My main role, within this team was to 
Implement new functionality, using ADF and Java, that would be included in the future Fusion application.
Provide guidelines and instruct other developers on how to implement and/or use  the new functionality.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="2005 - 2009"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<i className="fas fa-graduation-cap fa-2x"></i>}
                >
                    <h3 className="vertical-timeline-element-title">BSc Computer Science with a Year In Industry</h3>
                    <h4 className="vertical-timeline-element-subtitle">University of Kent, Canterbury, England</h4>
                    <p>
                    Final Year Project: Worked on a project involving making improvements to a
piece of software created by the university designed to teach beginners how to
learn Java.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="1998-2005"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<i className="fas fa-graduation-cap fa-2x"></i>}
                >
                    <h3 className="vertical-timeline-element-title">A-Levels + GCSEs</h3>
                    <h4 className="vertical-timeline-element-subtitle">Southgate School, London, England</h4>
                    <p>
                    A-Level grade C in Maths, ICT and Business.
Eight GCSE’s at grades A* – C including Science, English and Mathematics
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<i className="fas fa-star-of-life fa-2x" />}
                />
                </VerticalTimeline>
        )
    }
}