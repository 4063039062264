import React, {Component} from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';

export default class NavSearchBox extends Component {

    state = {
        selected: [],
      };
     
    getAnchor(url) {
        var urlParts   = url.split('#');
        return (urlParts.length > 1) ? urlParts[1] : null;
    }
    onSelect(items){
       if(items.selected[0] != null && items.selected[0].destination != null){
           var dest = items.selected[0].destination;
            if(dest.indexOf("http") > -1){
                //external link
                window.location.replace(dest);
            }
            else if(dest.indexOf("#") === 0){
                var id = this.getAnchor(dest);
                document.getElementById(id).scrollIntoView({behavior:"smooth", block:"start"});
            }
            else{
                window.location.replace(dest);
            }
        }
        this.setState({ items });
   }

   render() {
        return (
      
       
        <Typeahead
        id="basic-example"
        onChange={selected => this.onSelect({ selected })}
        options={this.props.sections}
        placeholder="Search"/>
        )
    }

    

}