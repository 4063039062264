import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';

export default class about extends Component {
    
    render() {
        return (
            <div id="about" className="skillssection">
                <h2>Skills</h2>

                
                <Fade bottom left cascade big>
                    <span>
                        
                    </span>
                </Fade>
            </div>
        )
    }
}