import React, { Component } from 'react'
import './NavBar.css';
import { NavLink } from "react-router-dom";
import NavSearchBox from '../navSearchBox/NavSearchBox';
import sections from '../../data/sections';
import AnimatedIcon from '../animatedIcon/AnimatedIcon';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { ReactComponent as Logo} from "./../../svg/DMLogo.svg";

export default class NavBar extends Component {
    
    onSelect(dest){
        if(dest!= null){
           document.getElementById(dest).scrollIntoView({behavior:"smooth", block:"start"});
        }
    }
    resetMenu(evt){
      console.log('resetMenu')
        var navbartoggle = document.getElementById('navbar-toggler');
        if(navbartoggle != null){
          // console.log('resetMenu remove: '+navbar.classList)
          // navbar.classList.remove('show')
          navbartoggle.click();
        }
    }
    render() {
        const fill = "fill-dark"
        const linkClass = "link-no-padding"
        const linkStyle = "link-color nav-link"
        return (


 <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark"
onSelect={selectedKey => this.onSelect(`${selectedKey}`)}>


  {/* <Navbar.Brand ><Nav.Link eventKey="home" className={linkClass}>
      <Logo className={fill} height="40" width="40" viewport="0 0 40 40"/></Nav.Link>
  </Navbar.Brand> */}
  <Navbar.Brand >
    <NavLink className={linkClass} to="/" >
    <AnimatedIcon/>
      {/* <Logo className={fill} height="40" width="40" viewport="0 0 40 40"/> */}
      </NavLink>
  </Navbar.Brand>

  {/* <Nav id="responsive-navbar-search">
      <NavSearchBox sections={sections}/>
  </Nav>   */}
  <Navbar.Toggle aria-controls="responsive-navbar-nav" id="navbar-toggler"/>
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto" activeKey="home"
        onSelect={selectedKey => this.onSelect(`${selectedKey}`)}>
      {/* <Nav.Link eventKey="about" >About</Nav.Link> */}
      {/* <Nav.Link eventKey="why">Why Me?</Nav.Link> */}
      <NavLink className={linkStyle} to="/" exact onClick={ evt => this.resetMenu(`${evt}`)}>About</NavLink>
      <NavLink className={linkStyle} to="/experience" onClick={ evt => this.resetMenu(`${evt}`)}>Experience</NavLink>
      <NavLink className={linkStyle} to="/skills" onClick={ evt => this.resetMenu(`${evt}`)}>Skills</NavLink>
      <NavLink className={linkStyle} to="/why" onClick={ evt => this.resetMenu(`${evt}`)}>Why Me?</NavLink>

      </Nav>
      
     </Navbar.Collapse>  
</Navbar>  







/*{ <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark"
onSelect={selectedKey => this.onSelect(`${selectedKey}`)}>


  <Navbar.Brand >
      <NavLink exact className={linkClass} to="/">
        <Logo class={fill} height="40" width="40" viewport="0 0 40 40"/>
      </NavLink>
      </Navbar.Brand>
  <Nav id="responsive-navbar-search">
      <NavSearchBox sections={sections}/>
  </Nav>  
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    
  <Nav.Link>About</Nav.Link>
      
      <NavLink className={linkClass} to="/about"><Nav.Link>About</Nav.Link></NavLink>
      
      <NavLink className={linkClass} to="/experience">Experience</NavLink>
      <NavLink className={linkClass} to="/why">Why Me?</NavLink>
  </Navbar.Collapse>  
</Navbar> }*/
)
    
}
}