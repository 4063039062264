import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, HashRouter} from "react-router-dom";
import NavBar from './components/navBar/NavBar';
import ContactCard from './components/contactCard/ContactCard';
import Fade from 'react-reveal/Fade';
import experience from './views/experience';
import skills from './views/skills';
import about from './views/about';
import { Jumbotron/*, Button */} from 'react-bootstrap';

function App() {
  if(1 === 1) {
    require('./theme/basic-dark-theme.css');
 } else {
    require('./theme/basic-light-theme.css');
 }

  return (
    <HashRouter>
      <div className="App">
        <div id="home"></div>
        <NavBar/>
        <Fade left big>
          <Jumbotron >
            <div className="jumboMask">
              <div className="jumboText">
                <p>David Mason</p>
              </div>
            </div>
          </Jumbotron>
        </Fade>
      <Route exact path="/" component={about}/>
      <Route exact path="/experience" component={experience}/>
      <Route exact path="/skills" component={skills}/>


     
      </div>
    </HashRouter>
    
  );
}

export default App;
