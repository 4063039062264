import React, { Component } from 'react'
import Snap from 'snapsvg-cjs';
import './AnimatedIcon.css';
import { ReactComponent as Logo} from "./../../svg/AnimDMIcon.svg";

export default class AnimatedIcon extends Component {
    componentDidMount() {
        this.selectPaths();
    }
    
    selectPaths(){
        var svg = document.getElementById("dmSvg");
        var s = Snap(svg);
        var animTime = 200;

        var larp = Snap.select('#larp');
        var larp2 = Snap.select('#larp3');
        var dp = Snap.select('#dp');
        var dp2 = Snap.select('#dp3');
        var ap = Snap.select('#ap');
        var ap2 = Snap.select('#ap3');
        
        var vp = Snap.select('#vp');
        var vp2 = Snap.select('#vp3');
        var ip = Snap.select('#ip');
        var ip2 = Snap.select('#ip3');
        var d2p = Snap.select('#d2p');
        var d2p2 = Snap.select('#d2p3');
        var mp = Snap.select('#mp');
        var mp2 = Snap.select('#mp3');
        var idotp = Snap.select('#idotp');
        var idotp2 = Snap.select('#idotp3');
        var sp = Snap.select('#sp');
        var sp2 = Snap.select('#sp3');
        var a2p = Snap.select('#a2p');
        var a2p2 = Snap.select('#a2p3');
        
        var op = Snap.select('#op');
        var op2 = Snap.select('#op3');
        var np = Snap.select('#np');
        var np2 = Snap.select('#np3');
        var rarp = Snap.select('#rarp');
        var rarp2 = Snap.select('#rarp3');
        var slp = Snap.select('#slp');
        var slp2 = Snap.select('#slp3');
        var crp = Snap.select('#crp');
        var crp2 = Snap.select('#crp3');
        
        
        var larpPoints = larp.node.getAttribute('d');
        var larp2Points = larp2.node.getAttribute('d');
        var dpPoints = dp.node.getAttribute('d');
        var dp2Points = dp2.node.getAttribute('d');
        var apPoints = ap.node.getAttribute('d');
        var ap2Points = ap2.node.getAttribute('d');
        var vpPoints = vp.node.getAttribute('d');
        var vp2Points = vp2.node.getAttribute('d');
        var ipPoints = ip.node.getAttribute('d');
        var ip2Points = ip2.node.getAttribute('d');
        var d2pPoints = d2p.node.getAttribute('d');
        var d2p2Points = d2p2.node.getAttribute('d');
        var mpPoints = mp.node.getAttribute('d');
        var mp2Points = mp2.node.getAttribute('d');
        var idotpPoints = idotp.node.getAttribute('d');
        var idotp2Points = idotp2.node.getAttribute('d');
        var spPoints = sp.node.getAttribute('d');
        var sp2Points = sp2.node.getAttribute('d');
        var a2pPoints = a2p.node.getAttribute('d');
        var a2p2Points = a2p2.node.getAttribute('d');
        
        var opPoints = op.node.getAttribute('d');
        var op2Points = op2.node.getAttribute('d');
        var npPoints = np.node.getAttribute('d');
        var np2Points = np2.node.getAttribute('d');
        var rarpPoints = rarp.node.getAttribute('d');
        var rarp2Points = rarp2.node.getAttribute('d');
        var slpPoints = slp.node.getAttribute('d');
        var slp2Points = slp2.node.getAttribute('d');
        var crpPoints = crp.node.getAttribute('d');
        var crp2Points = crp2.node.getAttribute('d');
        
        
        var toFancy = function(){
          larp.animate({ d: larp2Points }, animTime);
          dp.animate({d: dp2Points }, animTime);
          ap.animate({d: ap2Points }, animTime);
          vp.animate({d: vp2Points }, animTime);
          ip.animate({d: ip2Points }, animTime);
          d2p.animate({d: d2p2Points }, animTime);
          mp.animate({d: mp2Points }, animTime);
          idotp.animate({d: idotp2Points }, animTime);
          sp.animate({d: sp2Points }, animTime);
          a2p.animate({d: a2p2Points }, animTime);
          op.animate({d: op2Points }, animTime);
          np.animate({d: np2Points }, animTime);
          rarp.animate({ d: rarp2Points }, animTime);
          slp.animate({d: slp2Points }, animTime);
          crp.animate({ d: crp2Points }, animTime);
          
          
        }
        
        var toSimple = function(){
          larp.animate({ d: larpPoints }, animTime);
          dp.animate({d: dpPoints }, animTime);
          ap.animate({d: apPoints }, animTime);
          vp.animate({d: vpPoints }, animTime);
         ip.animate({d: ipPoints }, animTime);
         d2p.animate({d: d2pPoints }, animTime);
          mp.animate({d: mpPoints }, animTime);
         idotp.animate({d: idotpPoints }, animTime);
          sp.animate({d: spPoints }, animTime);
         a2p.animate({d: a2pPoints }, animTime);
          op.animate({d: opPoints }, animTime);
         np.animate({d: npPoints }, animTime);
         rarp.animate({ d: rarpPoints }, animTime);
          slp.animate({d: slpPoints }, animTime);
          crp.animate({ d: crpPoints }, animTime);
        }
        s.hover(function(){toFancy()},function(){toSimple()});
    }
    
    render() {
        const fill = "fill-dark"
        return (
            <Logo className={fill} height="40" width="200"/> 
                

            );
        }
    }