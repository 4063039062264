import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';
import { SocialIcon } from 'react-social-icons';

export default class about extends Component {
    
    render() {
        return (
            <div id="about" className="aboutsection">
                <div className="abouttextsection">
                <h2>About Me</h2>

                <span><span className="aboutme-keyword">Web Developer</span><span>, born and raised in London, England. Moved to the San Francisco Bay Area in early 2018. </span></span>
                <span>I have a passion for making things look good, but not only that, allowing others to do the same by creating simple to uptake re-usable components with a variety of technologies.</span>
                <span>I tend to focus most of my coding time on work projects, that Im not able to share, but Im hoping this page, built to teach myself ReactJS will be enough to impress...somewhat :-)  </span>
                
                </div>
                <Fade bottom left cascade big>
                    <span>
                        <SocialIcon url="mailto:davidpmase@gmail.com" />
                        <SocialIcon url="https://www.linkedin.com/in/davimaso/" />
                        <SocialIcon url="https://www.facebook.com/masonmason" />
                    </span>
                </Fade>
            </div>
        )
    }
}