import React, {Component} from 'react'
import Timeline from '../components/timeline/Timeline';
export default class experience extends Component {
    
    render() {
        return (

<div id="experience">
       <h2>Experience</h2>
        <Timeline />
</div>
        )
    }
}