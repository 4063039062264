import React, {Component} from 'react'

export default class ContactCard extends Component {
    getAlignText(){
        let pAlign = this.props.alignText;
        if(pAlign !== undefined){
            return pAlign;
        }
        return 'center';
    }
    getMaxWidth(){
        let mW = this.props.maxWidth;
        if(mW !== undefined){
            return mW;
        }
        return '300';
    }
    getBorderColor(){
        let bc = this.props.borderColor;
        if(bc !== undefined){
            return bc;
        }
        return '';
    }
    getStyle(){
        const divStyle = {
            textAlign: this.getAlignText(),
            maxWidth: this.getMaxWidth(),
            border: '1px solid ' + this.getBorderColor()                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
          };
          return divStyle;
    }
    render() {
        return (
            <div style={this.getStyle()}>
                <div>
                {this.props.name}
                </div>
                <div>
                {this.props.jobTitle}
                </div>
                <div>
                {this.props.addressLine1}
                </div>
                <div>
                {this.props.addressLine2}
                </div>
                <div>
                {this.props.addressLine3}
                </div>
                <div>
                {this.props.zipCode}
                </div>
                <div>
                {this.props.phoneNumber}
                </div>
                <div>
                {this.props.email}
                </div>
            </div>
        )
    }
}